import React from 'react';

const IconWordpress = () => (
  // <!-- Generated by IcoMoon.io -->
  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
  <title>wordpress</title>
  <path fill="#21759b" d="M28.625 9.1c1.12 2.049 1.757 4.4 1.757 6.9 0 5.305-2.875 9.941-7.151 12.433l4.393-12.703c0.82-2.053 1.093-3.695 1.093-5.152 0-0.54-0.035-1.040-0.093-1.48zM17.984 9.239c0.863-0.040 1.643-0.14 1.643-0.14 0.776-0.1 0.685-1.24-0.089-1.199 0 0-2.34 0.18-3.84 0.18-1.419 0-3.8-0.2-3.8-0.2-0.78-0.040-0.881 1.14-0.1 1.18 0 0 0.72 0.081 1.5 0.12l2.24 6.14-3.16 9.44-5.239-15.56c0.865-0.040 1.645-0.133 1.645-0.133 0.78-0.1 0.688-1.24-0.087-1.195 0 0-2.328 0.184-3.832 0.184-0.267 0-0.584-0.011-0.92-0.020 2.603-3.836 7.035-6.416 12.055-6.416 3.745 0 7.153 1.429 9.715 3.777-0.061-0.004-0.121-0.012-0.188-0.012-1.413 0-2.416 1.231-2.416 2.552 0 1.187 0.684 2.191 1.413 3.375 0.548 0.96 1.187 2.191 1.187 3.969 0 1.22-0.472 2.659-1.095 4.639l-1.433 4.78-5.2-15.48zM16 30.379c-1.412 0-2.775-0.204-4.064-0.583l4.316-12.541 4.42 12.116c0.032 0.071 0.067 0.135 0.104 0.199-1.493 0.524-3.1 0.812-4.776 0.812zM1.615 16c0-2.085 0.448-4.067 1.247-5.853l6.859 18.799c-4.795-2.332-8.104-7.251-8.105-12.945zM16 0c-8.82 0-16 7.18-16 16s7.18 16 16 16 16-7.18 16-16-7.18-16-16-16z"></path>
  </svg>
  
);

export default IconWordpress;