import React from 'react';

const IconGatsby = () => (
  // <!-- Generated by IcoMoon.io -->
  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
  <title>gatsby</title>
  <path fill="#639" d="M16.001 0.009c-8.9 0-15.992 7.092-15.992 15.991s7.092 15.993 15.991 15.993 15.992-7.092 15.992-15.992-7.091-15.992-15.991-15.992zM3.485 16.14l12.377 12.377c-6.815 0-12.377-5.563-12.377-12.377zM18.783 28.239l-15.019-15.019c1.249-5.563 6.257-9.735 12.236-9.735 4.151 0.008 7.831 2.017 10.128 5.113l0.024 0.034-1.807 1.528c-1.864-2.702-4.943-4.449-8.429-4.449-0.019 0-0.037 0-0.056 0h0.003c-0.019-0-0.042-0-0.065-0-4.396 0-8.135 2.816-9.51 6.743l-0.022 0.071 13.072 13.073c3.2-1.113 5.701-3.893 6.397-7.232h-5.424v-2.364h8.205c0 5.98-4.172 10.987-9.735 12.237z"></path>
  </svg>
  
);

export default IconGatsby;