import React from 'react';

const IconLiquid = () => (
// < !--Generated by IcoMoon.io-- >
  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 20" fill="lightblue">
    <title>Liquid</title>
    <path d="M17.016 8.109q1.359 0 2.953 0.797 1.125 0.563 2.016 0.563v1.969q-1.359 0-2.953-0.797-1.219-0.609-2.016-0.609-0.844 0-2.063 0.609-1.594 0.797-2.953 0.797t-2.953-0.797q-1.219-0.609-2.063-0.609-0.797 0-2.016 0.609-1.594 0.797-2.953 0.797v-1.969q0.891 0 2.016-0.563 1.594-0.797 2.953-0.797t2.953 0.797q1.125 0.563 2.063 0.563t2.063-0.563q1.594-0.797 2.953-0.797zM19.969 4.453q1.219 0.609 2.016 0.609v1.922q-1.359 0-2.953-0.797-1.219-0.609-2.016-0.609-0.844 0-2.063 0.609-1.594 0.797-2.953 0.797t-2.953-0.797q-1.219-0.609-2.063-0.609-0.797 0-2.016 0.609-1.594 0.797-2.953 0.797v-1.922q0.797 0 2.016-0.609 1.594-0.797 2.953-0.797t2.953 0.797q1.219 0.609 2.063 0.609t2.063-0.609q1.594-0.797 2.953-0.797t2.953 0.797zM17.016 12.563q1.359 0 2.953 0.797 1.125 0.563 2.016 0.563v1.969q-1.359 0-2.953-0.797-1.219-0.609-2.016-0.609-0.844 0-2.063 0.609-1.594 0.797-2.953 0.797t-2.953-0.797q-1.219-0.609-2.063-0.609-0.797 0-2.016 0.609-1.594 0.797-2.953 0.797v-1.969q0.891 0 2.016-0.563 1.594-0.797 2.953-0.797t2.953 0.797q1.125 0.563 2.063 0.563t2.063-0.563q1.594-0.797 2.953-0.797zM17.016 16.969q1.266 0 2.953 0.844 1.125 0.563 2.016 0.563v1.969q-1.359 0-2.953-0.797-1.219-0.609-2.016-0.609-0.844 0-2.063 0.609-1.594 0.797-2.953 0.797t-2.953-0.797q-1.219-0.609-2.063-0.609-0.797 0-2.016 0.609-1.594 0.797-2.953 0.797v-1.969q0.891 0 2.016-0.563 1.688-0.844 2.953-0.844t2.953 0.844q1.125 0.563 2.063 0.563t2.063-0.563q1.688-0.844 2.953-0.844z"></path>
  </svg>
  
);

export default IconLiquid;