import React from 'react';

const IconRuby = () => (
  // <!-- Generated by IcoMoon.io -->
  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
  <title>ruby</title>
  <path fill="#cc342d" d="M26.875 0.111c4.044 0.7 5.191 3.464 5.105 6.36l0.020-0.041-1.82 23.851-23.639 1.621c-1.964-0.083-6.341-0.263-6.541-6.383l2.193-4 4.429 10.344 3.74-12.192-0.040 0.009 0.021-0.040 12.34 3.941-1.861-7.241-1.32-5.2 11.76-0.759-0.82-0.68-8.443-6.883 4.879-2.721zM0 25.452v0.035-0.039zM6.84 6.764c4.748-4.711 10.876-7.495 13.229-5.12 2.349 2.369-0.14 8.14-4.897 12.848-4.751 4.709-10.804 7.645-13.152 5.276-2.355-2.369 0.060-8.289 4.816-13z"></path>
  </svg>
  
);

export default IconRuby;